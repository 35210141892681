import axios, { AxiosResponse } from "axios";
import { CLIENT_NAME, IS_BROKER_PORTAL, PLATFORM_TYPE } from "config";

const BASE_URL = process.env.REACT_APP_CHAT_SYSTEM_SERVER_URL;

if (IS_BROKER_PORTAL) {
  axios.defaults.headers["Platform-Type"] = PLATFORM_TYPE.BROKER_PORTAL;
  axios.defaults.headers["Client-Name"] = CLIENT_NAME;
}

export async function createWebsocket(
  payload: WebsocketPayload
): Promise<AxiosResponse<AxiosCommonResponse<any>>> {
  return await axios.post(`${BASE_URL}/websockets:create`, payload);
}

export async function createQuery(
  payload: QueryPayload
): Promise<AxiosResponse<AxiosCommonResponse<any>>> {
  return await axios.post(`${BASE_URL}/queries:create`, payload);
}
