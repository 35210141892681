import { callApi } from "api/base";
import { PLATFORM_TYPE } from "config";
import { PortalConfig } from "portalConfig/types";

export const getPortalConfig = async (clientName: string) => {
  return callApi<AxiosCommonResponse<PortalConfig>>({
    endpoint: "/config/intermediary-portal-configuration",
    method: "GET",
    headers: {
      "Platform-Type": PLATFORM_TYPE.BROKER_PORTAL,
      "Client-Name": clientName,
    },
  });
};
