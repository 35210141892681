import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { COUNTRY } from "config";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientSelector, customStylesSelector, paymentSelector, quotesSelector } from "store/selectors/selectors";
import CheckoutForm, { StripeCheckoutFormProps } from "./CheckoutForm";
import { RootState } from "store/store";
import { createPurchase } from "api/siaApplication";
import { setPurchase, setLoadingValue as setPurchaseLoading } from "store/features/paymentSlice";
import { setShowPaymentScreen } from "store/features/stepperSlice";
import { setErrorAndShowExitBtn } from "store/features/clientSlice";

const stripePromise = loadStripe(
  COUNTRY === "br"
    ? process.env.REACT_APP_STRIPE_API_KEY_BR // stere brazil
    : process.env.REACT_APP_STRIPE_API_KEY // brokery
);

type StripePaymentProps = StripeCheckoutFormProps & {
  paymentData?: PaymentPayload[];
};

const StripePayment: React.FC<StripePaymentProps> = ({
  onCancel,
  onSubmit: onGenerateClientSecret,
  customerEmail,
  paymentData,
}) => {
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const {
    clientAPIKey,
  } = useSelector((state: RootState) => {
    return state.client;
  });
  const payments = useSelector(paymentSelector);
  const clientSecret = payments?.purchase?.payment_gateway?.client_secret;
  const productIdentifier = useSelector(
    (state: RootState) =>
      state.client.availableProducts[0]?.product?.product_identifier
  );
  // This is the config from client sdk
  const { showAutoRenewalSwitch } = useSelector(clientSelector);
  // Auto renewal is available only for the DRONE product for Autopylot right now
  const isAutoRenewalAvailable = productIdentifier === "DRONE" && COUNTRY === "us";
  const showAutoRenew = showAutoRenewalSwitch && isAutoRenewalAvailable;
  const { selectedQuotes } =
    useSelector(quotesSelector);

  useEffect(() => {
    if (showAutoRenew) {
      const [selectedQuote] = selectedQuotes;
      const _createPurchase = async () => {
        try {
          dispatch(setPurchaseLoading(true));
          const res = await createPurchase(clientAPIKey, {
            for_type: "quote",
            for_ids: [selectedQuote.id],
            auto_renewal_consent: true,
          })
          dispatch(setPurchase(res.data));
          dispatch(setPurchaseLoading(false));
          dispatch(setShowPaymentScreen(true));
        } catch {
          dispatch(setErrorAndShowExitBtn(true));
        } finally {
          dispatch(setPurchaseLoading(false));
        }
      }
      _createPurchase();
    }
  }, [showAutoRenew, clientAPIKey, selectedQuotes, dispatch]);

  const appearance: Appearance = {
    theme: "flat",
    variables: {
      colorBackground: customStyles.input.backgroundColor,
      colorText: customStyles.input.color,
      fontSizeLg: "14px",
      fontSizeXl: "14px",
      fontFamily: customStyles.muiTheme.typography.fontFamily,
    },
  };

  const options: StripeElementsOptions = {
    appearance,
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap",
      },
    ],
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm
        onCancel={onCancel}
        onSubmit={onGenerateClientSecret}
        customerEmail={customerEmail}
        paymentData={paymentData}
        clientSecret={clientSecret}
        showAutoRenew={showAutoRenew}
      />
    </Elements>
  );
};

export default StripePayment;
