import { DocusealForm } from "@docuseal/react";
import React from "react";

interface DocuSealI {
  email: string;
  templateSlug: string;
  onComplete: VoidFunction;
  onLoad?: VoidFunction;
}

const DocuSeal: React.FC<DocuSealI> = ({
  email,
  templateSlug,
  onComplete,
  onLoad,
}) => {
  const onCompleteCallback = () => {
    onComplete();
  };

  const onLoadCallback = () => {
    if (onLoad) {
      onLoad();
    }
  };

  return (
    templateSlug && (
      <DocusealForm
        expand={false}
        // Removed the decline - not needed now
        // withDecline={true}
        email={email}
        src={`https://docuseal.co/d/${templateSlug}`}
        onLoad={onLoadCallback}
        onComplete={onCompleteCallback}
      />
    )
  );
};

export default DocuSeal;
